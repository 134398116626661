import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'neo-matris-input',
  templateUrl: './matris-input.component.html',
  styleUrls: ['./matris-input.component.scss']
})

export class MatrisInputComponent {

  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBlurMethod: EventEmitter<any> = new EventEmitter<any>();
  @Input() id: any;
  @Input() name: any;
  @Input() helpText: string = ''
  @Input() placeholder: string = ''
  @Input() label: string = ''
  @Input() isInputDisabled: boolean;
  @Input() inputWidth: string = '100%';
  @Input() floatRight: boolean = false;
  @Input() height: string = '67%'
  @Input() invalidInput: boolean;
  @Input() dialogInput: boolean;
  @Input() maxlength: string = ''
  @Input() type: string = ''
  @Input() isDisabledInputInvalid: boolean = false;

  onValueChange(event: any) {
    this.valueChange.emit({ id: this.id, event: event });
  }
  onBlur(event: any) {
    this.onBlurMethod.emit({ id: this.id, event: event });
  }
}
