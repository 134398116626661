import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
  selector: 'neo-matris-dropdown',
  templateUrl: './matris-dropdown.component.html',
  styleUrls: ['./matris-dropdown.component.scss']
})
export class MatrisDropdownComponent {

  @Input() options: any[] = [];
  @Input() optionLabel: string
  @Input() optionValue: string
  @Input() selectedOption: any
  @Input() showClear: boolean = false;
  @Input() placeholder: any
  @Input() isDisabled: any
  @Input() dropdownLabel: any
  @Input() id: any;
  @Input() autoDisplayEnabled: any;
  @Input() filter: any;
  @Input() customStyle: any = {};
  @Input() virtualScroll: boolean = false;
  @Input() virtualScrollItemSize: any;
  @Input() showError: boolean;
  @Input() showTooltip: boolean = false;
  @Output() dropdownChange = new EventEmitter<any>()

  onChange(event: any) {
    this.dropdownChange.emit({ id: this.id, event: event })
  }

}
