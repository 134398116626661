<!-- <div class="flex flex-column gap-2">
    <label style="font-weight: normal;font-size: smaller;font-family: 'Scania Sans Condensed';" [htmlFor]="id">{{label}}</label>
    <input style="font-family: 'Scania Sans Condensed';" pInputText [id]="id" aria-describedby="id" 
    [ngModel]="value" [placeholder]="placeholder" (change)="onValueChange($event)" -->

<!-- <div class="flex flex-column" [ngClass]="{'float-right-container': floatRight}"> -->
    <label class="label-font"  [htmlFor]="id">{{label}}</label>
    <br>
    <input pInputText [ngClass]="{'.p-inputtext.p-inputtext-sm': true, 'error-bg':invalidInput, 'dialog-custom-input': dialogInput}" [id]="id" 
    [disabled]="isInputDisabled" aria-describedby="id"
    [pTooltip]="invalidInput ? 'Please fill this field' : ''"
    [maxlength]="maxlength" [type]="type" tooltipPosition="top"
    [ngModel]="value" [style.width]="inputWidth" [style.height]="height"
    [ngStyle]="{'--disabled-input-color': (isDisabledInputInvalid ? 'red' : '#989898') }" 
    [placeholder]="placeholder"
    (change)="onValueChange($event)" 
    (blur)="onBlur($event)"
    oninput="this.value=this.value.slice(0,this.maxlength)"
    class="p-inputtext-sm"/>
    <small style="font-weight: normal;font-size: smaller;font-family: 'Scania Sans Condensed';" [id]="id">{{helpText}}</small>
<!-- </div> -->